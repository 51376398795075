import React from "react"

import { HeroLeftStyles } from "./hero-left-styles"

import Button from "../Ghost-Button/button"

const HeroLeft = () => {
  return (
    <div className="ghost-business-container">
      <HeroLeftStyles>
        <div className="hero-content">
          <h1>
            Bringing ideas to <span>life</span> with a high quality software{" "}
            <span>d</span>evelopment, <span>d</span>eployment and <span>d</span>
            elivery strategy
          </h1>
          <Button cta="Learn More" label="Banner Learn More" link="about" />
        </div>
      </HeroLeftStyles>
    </div>
  )
}

export default HeroLeft
