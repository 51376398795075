import styled from "styled-components"

const HeroLeftStyles = styled.div`
  .hero-content {
    /* transform: translateY(-50%); */
    /* left: 0; */
    /* right: 0; */
    // margin-top: 130px;
    text-align: center;
    height: 100%;
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // margin-left: 100px;
    // margin-right: auto;

    h1 {
      font-size: 1.75rem;
      line-height: 1.2;

      span {
        background-color: var(--ghostBlue);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    p {
      margin-top: 0;
      margin-bottom: 2rem;
      line-height: 1.2;
      font-size: 1.15rem;

      @media (min-width: 768px) {
        font-size: 1.35rem;
      }
    }

    @media screen and (min-width: 768px) {
      max-width: 100%;
      margin: 200px 0 0 30px;

      h1 {
        font-size: 3rem;
      }
    }

    // button,
    // .anchor {
    //   margin: 0 auto;
    // }

    //  .hybrid-button {
    //   margin-top: 20px;
    // }
  }
`

export { HeroLeftStyles }
