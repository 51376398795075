export const businessScenario1Styles = {
  bizContainer: "ghost-business-scenario-container",
  bizContent: "ghost-business-scenario-content",
  bizImage: "ghost-business-scenario-image",
}

export const businessScenario1StylesReversed = {
  bizContainer: "ghost-business-scenario-container-reversed",
  bizContent: "ghost-business-scenario-content-reversed",
  bizImage: "ghost-business-scenario-image-reversed",
}
