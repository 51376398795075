import React from "react"

import "./business-scenario-styles.scss"

import GhostButton from "../Ghost-Button/button"

const BusinessScenario = props => {
  const {
    styles: { bizContainer, bizContent, bizImage },
    h3Text,
    pText,
    img,
  } = props

  return (
    <div className={bizContainer}>
      <div className={bizImage}>
        <img alt="Business scenario" src={img} />
      </div>
      <div className={bizContent}>
        <h3 className="ghost-business-scenario-title">{h3Text}</h3>
        <p>{pText}</p>
        <GhostButton
          cta="Learn More"
          label="Business Scenario 1 Learn More"
          link="about"
        />
      </div>
    </div>
  )
}

export default BusinessScenario
