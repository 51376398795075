import React from "react"
import { Link } from "gatsby"

import "./choice-styles.scss"

const Choice = props => {
  return (
    <div className="ghost-choice-container">
      <h4 className="ghost-choice-header-title">{props.choiceTitle}</h4>
      <img src={props.img} alt={props.altName} />
      <p className="ghost-choice-header-paragraph">{props.choiceText}</p>
      <Link
        style={{
          color: "#2649fd",
          textAlign: "center",
          textDecoration: "none",
        }}
        to="/about"
      >
        Discover More {">>"}
      </Link>
    </div>
  )
}

export default Choice
