import React from "react"

import "./card.styles.scss"

const goToExternalLink = link => {
  window.open(link, "_blank")
}

const Card = ({ children, url }) => (
  <div onClick={() => goToExternalLink(url)} className="ghost-card">
    {children}
  </div>
)

export default Card
