import React from "react"

import "./choice-belt-styles.scss"

import Choice from "../Choice/choice"

import backendService from "../../images/backend-service.svg"
import frontendService from "../../images/frontend-service.svg"
import mentorshipService from "../../images/mentorship-service.svg"
import consultancyService from "../../images/consultancy-service.svg"

import {
  backendChoiceParagraphText,
  backendChoiceTitleText,
  frontEndChoiceParagraphText,
  frontEndChoiceTitleText,
  mentoringChoiceParagraphText,
  mentoringChoiceTitleText,
  businessPropositionsParagraphText,
  businessPropositionsTitleText,
} from "../../constants/choice-text"

const choicesCategoryCollection = [
  {
    serviceImg: backendService,
    imgAltName: "backend service",
    serviceTitle: backendChoiceTitleText,
    serviceText: backendChoiceParagraphText,
  },
  {
    serviceImg: frontendService,
    imgAltName: "frontend service",
    serviceTitle: frontEndChoiceTitleText,
    serviceText: frontEndChoiceParagraphText,
  },
  {
    serviceImg: mentorshipService,
    imgAltName: "mentorship service",
    serviceTitle: mentoringChoiceTitleText,
    serviceText: mentoringChoiceParagraphText,
  },
  {
    serviceImg: consultancyService,
    imgAltName: "consultancy service",
    serviceTitle: businessPropositionsTitleText,
    serviceText: businessPropositionsParagraphText,
  },
]

const ChoiceCategory = () => {
  return (
    <div className="ghost-choice-category-container">
      <div className="ghost-choices-container">
        {choicesCategoryCollection.map(
          ({ serviceImg, imgAltName, serviceText, serviceTitle }) => (
            <div className="gc1">
              <Choice
                img={serviceImg}
                altName={imgAltName}
                choiceTitle={serviceTitle}
                choiceText={serviceText}
              />
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default ChoiceCategory
