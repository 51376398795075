const b1H3Text =
  '"We need an experienced, senior full stack engineer to join our team who can hit the ground running!"'

const b1PText =
  "After a quick tour of your infrastructure and way of working, I can immediately start contributing to both the front and backend codebases. You can learn more about my current stack experience by clicking below."

const b4H3Text =
  '"I have an idea for a start up.. but I need software engineers to help us get started!"'

const b4PText =
  "I can help you scale up your infrastructure from the ground up, from ideas and concepts to production. I can lead the project, or join your existing team to get the job done."

const b2H3Text =
  '"Need a senior software engineer or technical lead to join your team or build your project?"'

// const b2PText =
//   "I can bring the necessary pre-sales and engineering consultation skills to compliment any multifunctional team in order to package a product or service successfully, resulting in the acquisition of new contracts or further funding."

const b2PText =
  "Whether you are large corporate or startup company, I can join your team to help implement and ship features fast and improve the codebase / infrastructure. I am ready to to listen to your story, understand your vision and align the technology to meet your business goals. Let's make your ideas become a reality."

const b3H3Text =
  '"Looking for a mentor / teacher who can blend the business world with software and technology?"'

const b3PText =
  "The technology and software space is an exciting, dynamic, creative and rewarding environment to be in. In order to thrive and provide real value, you need to be business savvy as well as being technically proficient. I can teach you how to be both and set you up for success."

export {
  b1H3Text,
  b1PText,
  b4H3Text,
  b4PText,
  b2H3Text,
  b2PText,
  b3H3Text,
  b3PText,
}
