import React, { useEffect, createRef } from "react"
import lottie from "lottie-web"

import animationFile from "../../animations/ghost-robot.json"

import "./hero-right-styles.scss"

const HeroRight = () => {
  let animationContainer = createRef()

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationFile,
      rendererSettings: {
        className: "ghost-resize-svg",
        viewBoxSize: "0 100 785 1200",
      },
    })

    return () => animation.destroy()
  }, [animationContainer])

  return <div className="ghost-animation-container" ref={animationContainer} />
}

export default HeroRight
