import React from "react"

import Card from "../Card/card"

import "./reference-belt.scss"

import backbase from "../../images/reference/backbase.png"
import codam from "../../images/reference/codam.png"
import info from "../../images/reference/info.png"
import lloyds from "../../images/reference/lloyds.png"
import nn from "../../images/reference/nn.png"
import takeaway from "../../images/reference/takeaway.png"
import gs4e from "../../images/reference/gs4e.png"
import han from "../../images/reference/han.png"
import opc from "../../images/reference/opc.png"
import sgc from "../../images/reference/sgc.png"

const images = [
  {
    imageRef: backbase,
    alt: "backbase",
    cssClass: "reference-company-image-backbase",
    url: "https://www.backbase.com",
  },
  {
    imageRef: codam,
    alt: "codam",
    cssClass: "reference-company-image-codam",
    url: "https://www.codam.nl",
  },
  {
    imageRef: info,
    alt: "info",
    cssClass: "reference-company-image-info",
    url: "https://info.nl",
  },
  {
    imageRef: lloyds,
    alt: "lloyds",
    cssClass: "reference-company-image-lloyds",
    url: "https://www.lloydsbank.com",
  },
  {
    imageRef: nn,
    alt: "nn",
    cssClass: "reference-company-image-nn",
    url: "https://www.nn.nl",
  },
  {
    imageRef: takeaway,
    alt: "takeaway",
    cssClass: "reference-company-image-takeaway",
    url: "https://www.takeaway.com",
  },
  {
    imageRef: sgc,
    alt: "sgc",
    cssClass: "reference-company-image-sgc",
    url: "https://www.startgreen.nl/",
  },
  {
    imageRef: gs4e,
    alt: "gs4e",
    cssClass: "reference-company-image-gs4e",
    url: "https://www.gs4e.com",
  },
  {
    imageRef: han,
    alt: "han",
    cssClass: "reference-company-image-han",
    url: "https://www.han.nl",
  },
  {
    imageRef: opc,
    alt: "opc",
    cssClass: "reference-company-image-opc",
    url: "https://www.oneplanetcrowd.com",
  },
]

const ReferenceBelt = () => {
  return (
    <div className="ghost-reference-belt-companies">
      {images.map(({ imageRef, alt, cssClass, url }) => (
        <Card url={url}>
          <img src={imageRef} alt={alt} className={cssClass} />
        </Card>
      ))}
    </div>
  )
}

export default ReferenceBelt
