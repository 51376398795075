import React from "react"

import Hero from "../components/Hero/hero"
import ReferenceBelt from "../components/Reference-Belt/reference-belt"
import BusinessScenario from "../components/Business-Scenario/business-scenario"
import H3Title from "../components/H3-Title/h3-title"
import MissionStatement from "../components/Mission-Statement/mission-statement"
import ChoiceBelt from "../components/Choice-Belt/choice-belt"
import Contact from "../components/New-Contact-Form/new-contact-form"
import TestimonialBelt from "../components/Testimonial-Belt/testimonial-belt"

import bizScenario1Img from "../images/ghost-service-scenario-1-new.svg"
import bizScenario2Img from "../images/ghost-service-scenario-2.svg"
import bizScenario3Img from "../images/ghost-service-scenario-3.svg"
import bizScenario4Img from "../images/ghost-service-scenario-4.svg"

import {
  b1H3Text,
  b1PText,
  b4H3Text,
  b4PText,
  b2H3Text,
  b2PText,
  b3H3Text,
  b3PText,
} from "../constants/business-scenario-text"

import {
  businessScenario1Styles,
  businessScenario1StylesReversed,
} from "../styles/customStyles"

const HomePage = () => {
  return (
    <>
      <Hero />
      {/* <MissionStatement /> */}
      <ReferenceBelt />
      {/* <BusinessScenario
        styles={businessScenario1Styles}
        img={bizScenario1Img}
        h3Text={b1H3Text}
        pText={b1PText}
      /> */}
      <BusinessScenario
        styles={businessScenario1StylesReversed}
        img={bizScenario2Img}
        h3Text={b2H3Text}
        pText={b2PText}
      />
      <BusinessScenario
        styles={businessScenario1Styles}
        img={bizScenario3Img}
        h3Text={b3H3Text}
        pText={b3PText}
      />
      {/* <BusinessScenario
        styles={businessScenario1StylesReversed}
        img={bizScenario4Img}
        h3Text={b4H3Text}
        pText={b4PText}
      /> */}
      <ChoiceBelt />
      {/* <H3Title titleText="What Other People Are Saying About Me:" /> */}
      <TestimonialBelt />
      <Contact />
    </>
  )
}

export default HomePage
