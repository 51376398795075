import React from "react"

import "./hero.styles.scss"

import HeroLeft from "../Hero-Left/hero-left"
import HeroRight from "../Hero-Right/hero-right"

const Hero = () => {
  return (
    <div className="ghost-hero-container">
      <HeroLeft />
      <HeroRight />
    </div>
  )
}

export default Hero
